<template>
  <div>
    <b-button size="small">Notifications</b-button> | <b-button size="small">Documents</b-button> | <b-button size="small">Flights</b-button>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>